import { postRequest } from "@/utils/request_utils"

export default {
  // 车牌档案--新增车辆信息
  addCarArchives: (data)=> {
    return postRequest(`/api-base/cararchive/create`,{data});
  },
  // 车牌档案--删除车辆信息
  deleteCarArchives: (data)=> {
    return postRequest(`/api-base/cararchive/delete`,{data});
  },
  // 车牌档案--查询车辆信息列表
  // queryCarArchives: (data)=> {
  //   return postRequest(`/api-base/cararchive/selectCarArchive`,{data});
  // },
  queryCarArchives: (data)=> {
    return postRequest(`/api-base/cararchive/selectCarArchiveOnEncryption`,{data});
  },
  // 车牌档案--修改车辆信息
  updateCarArchives: (data)=> {
    return postRequest(`/api-base/cararchive/update`,{data});
  },
  // 车牌档案--查询车辆详情
  queryCarInfo: (data)=> {
    return postRequest(`/api-base/cararchive/selectOne`,{data});
  },
}
