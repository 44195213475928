<template>
  <div class="app-container flex flex-v">
    <div class="searchBar bg-white" style="border: 1px solid white">
      <el-form ref="searchForm" :model="searchForm" label-width="80px" size="mini">
        <el-row :gutter="20">
          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
            <el-form-item label="车辆编号">
              <el-input v-model="searchForm.carId" clearable placeholder="车辆编号"/>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">-->
<!--            <el-form-item label="车牌号">-->
<!--              <el-input v-model="searchForm.licenseNumber" clearable placeholder="车牌号"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
            <el-form-item style="text-align: right">
              <el-button
                  icon="el-icon-search"
                  type="primary"
                  size="mini"
                  @click="searchFormSubmit"
              >查 询
              </el-button>
              <el-button
                  icon="el-icon-plus"
                  type="primary"
                  plain
                  size="mini"
                  @click="addRecord"
              >新 增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="bg-white main-content-wrapper margin-10 flex flex-1 flex-v">
      <el-table
        stripe
        class="flex-1"
        style="margin-top: 15px;"
        height="400px"
        v-loading="tableLoading"
        size="mini"
        :data="tableList"
        :header-cell-style="{ background:'#f2f2f2' }"
      >
        <el-table-column label="序号" prop="serviceCode" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="车辆编号" prop="carId" align="center" />
<!--        <el-table-column label="车牌号" prop="licenseNumber" align="center" >-->
<!--          <template #default="scope">-->
<!--            <span>{{scope.row.licenseNumber && scope.row.licenseNumber.substring(0,2)+'***'+scope.row.licenseNumber.substring(5,scope.row.licenseNumber.length)}}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="创建人" prop="createName" align="center" />
        <el-table-column label="创建日期" prop="createTime" align="center" show-overflow-tooltip />
        <el-table-column show-overflow-tooltip label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini"  @click="handleEditRecord(scope.row)" >编辑</el-button>
            <el-button type="text" size="mini"  @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination pagi-sty">
        <!-- 分页-->
        <Pagination
          :page.sync="tablePage.currentPage"
          :total="tablePage.total"
          :limit.sync="tablePage.pageSize"
          @pagination="paginationChange"
        />
      </div>
    </div>
    <!-- 设置车辆信息 -->
    <div>
      <el-dialog
        :title="diaTitle"
        :visible.sync="dialogVisible"
        width="40%"
        >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form ref="cForm" :model="cForm" label-width="60px" size="mini">
                <el-form-item label="车牌号">
                  <el-input v-model="cForm.licenseNumber" placeholder="车牌号" />
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="handleAddUpdate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import tables from '@/mixins/tables'
import licenseService from '@/api/license-archives'

export default {
  // 车牌档案
  name: 'car-license-archives',
  components: { Pagination },
  mixins: [tables],
  data() {
    return {
      searchForm: {
        carId: '',
        licenseNumber: ''
      },
      cForm: {
        licenseNumber: ''
      },
      diaTitle: '',
      dialogVisible: false,
      currentItem: {}
    }
  },
  computed:{
    editButton(){
      let edit_button = false;
      if(this.$store.state.user.menuListAll){
        let obj = this.$store.state.user.menuListAll.find(f => f.code === 'base-car');
        edit_button =  obj && obj.children.length> 0 &&
            obj.children.find(item => item.code === 'car_edit');
      }
      return edit_button;
    },
  },
  methods: {
    /**
     * ！！！必须方法，获取 list 接口
     *
     * @params {Object} data 请求数据：包含 searchForm 和 tablePage 属性
     * @return {Promise} Promise 获取 list 接口的异步方法
     */
    async getListApi(data) {
      return new Promise((resolve, reject) => {
        licenseService.queryCarArchives(data).then(data => {
          resolve(data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 新增车辆记录
    addRecord() {
      this.diaTitle = '新增车辆档案'
      this.dialogVisible = true
      this.cForm.licenseNumber = ''
      this.currentItem = {}
    },
    // 修改车辆记录
    handleEditRecord(row) {
      licenseService.queryCarInfo({id: row.id}).then(resp => {
        if(resp.success){
          this.currentItem = resp.data;
          this.cForm.licenseNumber = resp.data.licenseNumber || ''
          this.diaTitle = '修改车辆档案'
          this.dialogVisible = true
        }
      })
    },
    // 新增或者更新车辆档案
    handleAddUpdate() {
      if (!this.cForm.licenseNumber) {
        this.$message.error('请先填写完整车牌号')
        return
      }
      // 存在车辆编号则为更新
      if (this.currentItem.carId) {
        this.updateRecordService()
      } else {
        this.addRecordService()
      }
    },
    // 新增服务
    addRecordService() {
      licenseService.addCarArchives(this.cForm).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.dialogVisible = false
          this.searchFormSubmit()
        }else {
          this.$message.error(res.message)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    // 更新服务
    updateRecordService() {
      this.currentItem.licenseNumber = this.cForm.licenseNumber
      licenseService.updateCarArchives(this.currentItem).then(res => {
        this.$message.success('操作成功')
        this.dialogVisible = false
        this.searchFormSubmit()
      }).catch(e => {
        console.log(e)
      })
    },
    // 删除
    handleDelete(row) {
      this.$confirm(`确定要删除吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        licenseService.deleteCarArchives(row).then(res => {
          this.$message.success('操作成功')
          this.searchFormSubmit()
        }).catch(e => {
          console.log(e)
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.app-container {
  background-color: #f6f6f6;
  height: 100%;
}

.icon {
  font-size: 16px;
  line-height: 0 !important;
}

::v-deep .el-pagination__total {
  float: right;
}

::v-deep .el-tooltip__popper {
  line-height: 0.5;
}

.el-form {
  background: #FFFFFF;
  padding: 20px 20px 0 20px;
  margin: 10px;
  .el-col {
    padding-left: 20px;
    padding-right: 20px;
    ::v-deep .el-form-item__label {
      font-size: 12px;
    }
    .el-input {
      width: 240px;
    }
  }
}
::v-deep .el-table__header-wrapper .el-checkbox {
  right: 4px;
}
.pagi-sty {
  width: 100%;
  padding-top: 20px;
  text-align: right;
}
</style>
